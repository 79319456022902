import React, { Component } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MailBox from "../components/mailbox"
import Link from "../components/link"

import PhoneImg from "../images/iphone.png"
import CardImg from "../images/card.jpg"
import ImgAppDashboard from "../images/app-dashboard.png"
import ImgBudget from "../images/budget.png"
import ImgServices from "../images/services.png"
import ImgSplitBill from "../images/split-bill.png"
import ImgSupport from "../images/support.png"
import ImgSecurity from "../images/security.png"


export const StyledIndexPage = styled.div`
  @media (max-width: 1080px) {
    overflow-x: hidden;
  }
`

export const Text = styled.div`
  flex: 1 0 50%;
  max-width: 50%;
  padding-right: calc(5% + 1rem);
  margin: auto -5% auto auto;

  @media (max-width: 519px) {
    flex: 1 0 auto;
    max-width: 100%;
    padding-right: 0px;
    text-align: center;
  }
`

export const FirstScreen = styled.div`
  padding: 5.25rem 0px 0px 3rem;
  max-width: 86rem;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  margin: 0px auto;
  z-index: 1;
  position: relative;

  & ${Text} {
    position: relative;
    flex: 1 0 60%;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 750px;
    margin: auto -15% auto 0px;
  }

  @media (max-width: 519px) {
    padding: 0px 1.5rem;
    min-height: 0px;
    height: auto;
    margin: 8.25rem 0px 12rem;
    padding: 0px 2rem;
    flex-direction: column;

    & ${Text} {
      flex: 1 0 auto;
      max-width: 100%;
      padding-right: 0px;
      margin: auto;
      text-align: center;
    }
  }

  @media (max-width: 1080px) {
    height: auto;
  }
`

export const TitleWrap = styled.div`
  margin: auto 0px;

  @media (max-width: 1080px) {
    max-width: 100%;
    margin: 0px 0px 2rem;
  }
`

export const PageTitle = styled.div`
  max-width: 540px;
`

export const Heading = styled.h1`
  font-weight: 400;
  line-height: 1.25;
  font-size: 3rem;
  margin-top: 0px;

  @media (min-width: 1081px) {
    font-size: 4rem;
  }
`

export const PageSubtitle = styled.div`
  color: rgb(139, 149, 158);
  margin: 1.25rem 0px;

  @media (min-width: 768px) {
    margin: 1.25rem 2rem 1.25rem 0px;
  }
`
export const Widget = styled.div`
  display: flex;
  margin-top: 2.5rem;
`

export const WidgetLocal = styled.div`
  min-height: 90px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  z-index: 1;

  @media (max-width: 519px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Button = styled.button`
  display: inline-block;
  padding: 1em 4.125em;
  border: medium none;
  border-radius: 2em;
  line-height: 1.5em;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  margin-bottom: 0.5em;
  background-color: rgb(255, 0, 135);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`

export const WidgetSubmitBtn = styled(Button)`
  max-width: 28rem;
  display: inline-block;
  position: relative;
  width: 140px;
  height: 56px;
  margin-left: 1rem;
  padding: 0px;
  margin-bottom: 0px;
  z-index: 1;
  transition: color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0s, stroke 450ms cubic-bezier(0.23, 1, 0.32, 1) 0s, fill 450ms cubic-bezier(0.23, 1, 0.32, 1) 0s, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`

export const Image = styled.div`
  position: relative;
  align-self: center;
  flex: 1 0 40%;
  max-width: 40%;
  margin: auto;
  transition: transform 0s ease 0s, opacity 0.3s ease 0.2s;
  opacity: 1;

  @media (max-width: 519px) {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    flex: 1 1 0%;
  }

  @media (max-width: 1080px) {
    opacity: 1;
    margin-top: 6rem;
  }
`

export const StyledPhone = styled.div`
  position: relative;
  width: 36.25vh;
  height: 74.37vh;
  max-width: 290px;
  max-height: 600px;
  margin: auto;

  @media (max-width: 519px) {
    width: 15.75rem;
    height: 34rem;
  }

  @media (min-width: 520px) {
    &:before {
      content: "";
      position: absolute;
      inset: 15px 1px 1px 3px;
      border-radius: 3rem;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 24px 32px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("${PhoneImg}");

    @media (max-width: 519px) {
      background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
    }
  }
`

export const StyledImage = styled.img`
  position: relative;
  top: 5%;
  width: 33vh;
  height: 69vh;
  max-width: 265px;
  max-height: 555px;
  margin: 0px 0px 0px 4.5%;
  z-index: 1;
  border-radius: 1rem;

  @media (max-width: 519px) {
    display: block;
    margin: 0px auto;
    top: 0px;
    width: auto;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 30px;
  }

  @media (min-width: 1081px) {
    border-radius: 2rem;
  }
`

export const StyledCard = styled.div`
  position: absolute;
  display: block;
  width: 45vh;
  height: 28.375vh;
  max-width: 360px;
  max-height: 227px;
  top: 44%;
  left: 29%;
  opacity: 1;
  transform: rotate(120deg);
  transition-property: all;
  transition-timing-function: ease-out;
  z-index: -1;

  @media (max-width: 519px) {
    opacity: 1;
  }

  &:before {
    content: "";
    position: absolute;
    top: 1%;
    left: 1%;
    right: 1%;
    bottom: 1%;
    margin-top: auto;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
    background-color: rgb(255, 95, 0);
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 24px 32px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("${CardImg}");
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
  }
`

export const PhoneWrap = styled.div`
  position: relative;
`

export const StickyWrap = styled.div`
  padding: 0px 0px 0px 3rem;
  margin: auto;
  max-width: 86rem;
  position: sticky;
  top: 1px;
  width: 100vw;
  height: 1px;
  min-height: 0px;
  z-index: 10;
  pointer-events: none;

  @media (max-width: 519px) {
    padding: 0px 1.5rem;
    margin: 8.25rem 0px 12rem;
    height: auto;
    min-height: 0px;
  }
`
export const PhoneWrap2 = styled.div`
  display: flex;
  height: 100vh;
  min-height: 100vh;
  margin: auto;

  @media (max-width: 1080px) {
    display: none;
  }
`

export const TextMargin = styled.div`
  flex: 1 0 50%;
  max-width: 50%;
  padding-right: calc(5% + 1rem);
  margin: auto -5% auto auto;
  text-align: left;
`

export const StickyPhone = styled.div`
  position: relative;
  flex: 1 0 55%;
  align-self: center;
  max-width: 55%;
  margin: auto;
`

export const StyledDevicePhone = styled.div`
  position: relative;
  top: 5%;
  width: 33vh;
  height: 69vh;
  max-width: 265px;
  max-height: 555px;
  margin: 0px 0px 0px 4.5%;
  z-index: 1;
  overflow: hidden;
`

export const StyledImage2 = styled.img`
  display: block;
  position: relative;
  width: 33vh;
  height: 69vh;
  max-width: 265px;
  max-height: 555px;
  opacity: ${ props => props.index === props.active ? "1" : "0" };
  visibility: ${ props => props.index === props.active ? "visible" : "hidden" };
  transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  top: ${ props => -(props.index) * 100 }%;

  @media (min-width: 1081px) {
    border-radius: 1.5rem;
  }
`

export const Screen = styled.div`
  margin: auto;
  max-width: 86rem;
  min-height: 100vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  padding: 0px 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    min-height: 100vh;
    padding: 0px 2.7rem;
  }

  @media (max-width: 519px) {
    padding: 0px 1.5rem;
    margin: 8.25rem 0px 12rem;
    height: auto;
    min-height: 0px;
  }
`

export const TextBlock = styled.div`
  flex: 1 0 auto;
  max-width: 100%;
  padding-right: 0px;
  margin: auto;
  text-align: center;

  @media (min-width: 768px) {
    flex: 1 0 50%;
    max-width: 50%;
    padding-right: calc(5% + 1rem);
    margin: auto -5% auto auto;
    text-align: left;
  }
`

export const HeadingH2 = styled.h2`
  color: rgb(25, 28, 31);
  font-size: 2.5rem;
  line-height: 3.5rem;
  margin: 0px;

  @media (min-width: 1081px) {
    font-size: 3rem;
  }
`

export const DL = styled.div`
  display: block;
  width: 100%;
`

export const DT = styled(DL)`
  display: block;
  width: 100%;
  margin: 1.3rem 0px 0px;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: rgb(25, 28, 31);
`
export const DD = styled(DL)`
  display: block;
  width: 100%;
  margin: 0.63rem 0px;
  color: rgb(139, 149, 158);
`
export const Image2 = styled.div`
  position: relative;
  flex: 1 0 55%;
  align-self: center;
  max-width: 55%;
  margin: auto;
  opacity: 0;

  @media (max-width: 1080px) {
    opacity: 1;
  }

  @media (max-width: 519px) {
    margin-top: 4rem;
    flex: 1 1 0% !important;
    display: block;
    max-width: 100%;
  }
`


class IndexPage extends Component {

  state = {
    formFocused: false,
    formEmail: null,
    formInvalid: false,
    formMessage: null,
    formBtnDisabled: true,
    activeImage: 0,
  }

  imageSections = [
    {
      "ref": React.createRef(),
      "url": ImgBudget,
    },
    {
      "ref": React.createRef(),
      "url": ImgServices,
    },
    {
      "ref": React.createRef(),
      "url": ImgSplitBill,
    },
    {
      "ref": React.createRef(),
      "url": ImgSupport,
    },
    {
      "ref": React.createRef(),
      "url": ImgSecurity,
    },
  ];

  _handleOnFocus = (e) => {
    this.setState({
      formFocused: true
    });
  }

  _handleOnBlur = (e) => {
    if (e.target.value)
      return;

    this.setState({
      formFocused: false
    });
  }

  _handleOnInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {[name]: value},
      () => { this.validateField(name, value) }
    );
  }

  validateField(fieldName, value) {
    let formBtnDisabled = this.state.formBtnDisabled;

    switch(fieldName) {
      case 'email':
        formBtnDisabled = !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        break;
      default:
        break;
    }

    this.setState({
      formBtnDisabled: formBtnDisabled,
    });
  }

  _handleSubmit = (e) => {
      e.preventDefault();

      this.setState({
        formBtnDisabled: true
      });

      addToMailchimp(this.state.email, this.state)
          .then(({ msg, result }) => {
            console.log(result, msg);

            this.setState({
              formInvalid: result === 'success' ? false : true,
              formMessage: result === 'success' ? "Gracias por subscribirte!" : "Este correo ya esta registrado",
              formBtnDisabled: false
            });
          });
  }

  handleObserver = (entries) => {
    entries.forEach(entry => {
      if (!entry.isIntersecting)
        return;

      this.imageSections.forEach((image, index) => {
        if (entry.target === image.ref.current && this.state.activeImage !== index) {
          this.setState({
            activeImage: index
          });

          return;
        }
      });
    });
  }

  constructor() {
    super();

    this.emailInput = React.createRef();
  }

  componentDidMount() {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5
    };

    this.observer = new IntersectionObserver(this.handleObserver, options);

    this.imageSections.forEach(image => {
      this.observer.observe(image.ref.current);
    });

    if (this.props.location.hash === "#register") {
      this.emailInput.current.focus();
    }
  }

  componentWillUnmount() {
    this.imageSections.forEach(image => {
      this.observer.unobserve(image.ref.current);
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="La primer cuenta de la que te vas a enamorar" />
        <StyledIndexPage>
          <FirstScreen>
            <Text>
              <TitleWrap>
                <PageTitle>
                  <Heading>
                    La primer cuenta de la que te vas a enamorar
                  </Heading>
                  <PageSubtitle>
                    En KKO hemos escuchado las verdaderas necesidades de los Mexicanos. Sabemos muy bien que los bancos no han cumplido con todas tus expectativas.<br />
                    Por eso estamos muy orgullosos de la cuenta que hemos creado para ti, y pronto podrás disfrutarla plenamente.<br />
                    <br />
                    ¡Únete a la lista de espera!
                  </PageSubtitle>
                </PageTitle>
                <Widget>
                  <WidgetLocal>
                    <MailBox
                      ref={this.emailInput}
                      formFocused={this.state.formFocused}
                      handleOnFocus={this._handleOnFocus}
                      handleOnBlur={this._handleOnBlur}
                      handleOnInput={this._handleOnInput}
                      formInvalid={this.state.formInvalid}
                      formMessage={this.state.formMessage}
                    />
                    <WidgetSubmitBtn onClick={this._handleSubmit} disabled={this.state.formBtnDisabled}>Regístrate</WidgetSubmitBtn>
                  </WidgetLocal>
                </Widget>
              </TitleWrap>
            </Text>
            <Image>
              <StyledPhone>
                <StyledImage src={ImgAppDashboard} />
                <StyledCard />
              </StyledPhone>
            </Image>
          </FirstScreen>
          <PhoneWrap>
            <StickyWrap>
              <PhoneWrap2>
                <TextMargin />
                <StickyPhone>
                  <StyledPhone>
                    <StyledDevicePhone>
                    {this.imageSections.map((image, idx) => (
                      <StyledImage2 key={idx} src={image.url} index={idx} active={this.state.activeImage} />
                    ))}
                    </StyledDevicePhone>
                  </StyledPhone>
                </StickyPhone>
              </PhoneWrap2>
            </StickyWrap>
            <Screen ref={this.imageSections[0].ref}>
              <TextBlock>
                <HeadingH2>Mueve tu dinero con tus propias reglas</HeadingH2>
                <DL>
                  <DT></DT>
                  <DD>Establece presupuestos inteligentes, envía y pide dinero a tus amigos, realiza pagos recurrentes, define limites de gasto por categoría y mucho más...</DD>
                </DL>
              </TextBlock>
              <Image2>
                <StyledPhone>
                  <StyledImage src={ImgBudget} />
                </StyledPhone>
              </Image2>
            </Screen>
            <Screen ref={this.imageSections[1].ref}>
              <TextBlock>
                <HeadingH2>¡Simplifica tu vida diaria!</HeadingH2>
                <DL>
                  <DT></DT>
                  <DD>
                    Ahora puedes pagar tus facturas de agua, luz, internet y mucho más directamente desde la app.<br />
                    <br />
                    Simplemente escanea el código de barras o toma una foto de tu recibo e indica la cantidad a pagar.<br />
                    <br />
                    ¡Así de sencillo!
                  </DD>
                </DL>
              </TextBlock>
              <Image2>
                <StyledPhone>
                  <StyledImage src={ImgServices} />
                </StyledPhone>
              </Image2>
            </Screen>
            <Screen ref={this.imageSections[2].ref}>
              <TextBlock>
                <HeadingH2>Mis amigos</HeadingH2>
                <DL>
                  <DT>Divide la cuenta</DT>
                  <DD>
                    Pagar la cuenta en un grupo siempre es complicado. Con KKO puedes dividir la cuenta de manera muy sencilla.<br />
                    Cuentas claras, amistades largas!
                  </DD>
                  <DT>Haz pagos instantaneos</DT>
                  <DD>Cuando envíes dinero a tus amigos KKO, lo recibirán al instante</DD>
                  <DT>Envia/Pide dinero</DT>
                  <DD>Tus amigos no te pagaron el boleto para el estadio? Pídeselos por KKO</DD>
                </DL>
              </TextBlock>
              <Image2>
                <StyledPhone>
                  <StyledImage src={ImgSplitBill} />
                </StyledPhone>
              </Image2>
            </Screen>
            <Screen ref={this.imageSections[3].ref}>
              <TextBlock>
                <HeadingH2>Te escuchamos</HeadingH2>
                <DL>
                  <DT>Necesitas ayuda?</DT>
                  <DD>Habla con nosotros por <Link target="_blank" href="https://wa.me/525584218444">WhatsApp</Link></DD>
                </DL>
              </TextBlock>
              <Image2>
                <StyledPhone>
                  <StyledImage src={ImgSupport} />
                </StyledPhone>
              </Image2>
            </Screen>
          </PhoneWrap>
          <Screen ref={this.imageSections[4].ref}>
            <TextBlock>
              <HeadingH2>Seguridad de tu cuenta y tu tarjeta</HeadingH2>
              <DL>
                <DT>Activa o desactiva tu tarjeta</DT>
                <DD>
                  Tan sencillo como apretar un botón, puedes congelar y descongelar tu tarjeta.<br />
                  No importa si simplemente se te olvidó dónde la dejaste!
                </DD>
                <DT>Tu tarjeta a la medida</DT>
                <DD>Elige las opciones que deseas activar, tal como pagos en línea, retiro en cajeros y mucho más!</DD>
                <DT>Seguridad basada en tu geolocalizacion</DT>
                <DD>Activa la seguridad basada en tu ubicación y cuando tu tarjeta se utilice en una localización diferente a la tuya, bloquearemos automáticamente todos los pagos y te lo haremos saber.</DD>
              </DL>
            </TextBlock>
            <Image2>
              <StyledPhone>
                <StyledImage src={ImgSecurity} />
              </StyledPhone>
            </Image2>
          </Screen>
        </StyledIndexPage>
      </Layout>
    );
  }
}

export default IndexPage
